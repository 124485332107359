import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { uniq } from 'underscore';

import { SkyviewTimelineContainer, TimelineStore } from '../../../js/stores/timeline-store';
import { formattedDateTime } from '../../../js/utils/dateUtils';
import { sessionStorageUtils } from '../../../js/utils/session-storage-utils';
import { isValidUuidV4 } from '../../../js/utils/text/validation';
import { isDefined } from '../../../js/utils/variables';
import EmptyStateTimeline from '../../../static/empty_state_timeline.svg';
import { useDialog } from '../../hooks/use-dialog';
import { useToast } from '../../hooks/use-toast';
import { ProjectContext } from '../../state/project-state';
import { Center } from '../../styles/center';
import { Button } from '../button/button';
import { DataTable, DataTableColumns } from '../data-table/data-table';
import { IconPanel } from '../icon-panel/icon-panel';
import { OverlayLoader } from '../overlay-loader/overlay-loader';
import { PageTitle } from '../page-title';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';
import { useCreateProjectTimeline } from './hooks/use-create-project-timeline';
import { useDeleteProjectTimeline } from './hooks/use-delete-project-timeline';
import { useUpdateProjectTimeline } from './hooks/use-update-project-timeline';
import { TimelineDialog } from './timeline-dialog';
import { getArtifactDisplayName, OnCreateOptions, OnDeleteOptions, OnUpdateOptions } from './types';

type TimelineDataItem = {
  index: number;
  id: string;
  collectionDate: Date;
  tiledModelName?: string;
  orthotileName?: string;
  pointCloudName?: string;
  comment?: string;
  geodataName?: string;
};

const pageSize = 20;

const tableStyle = {
  rowCell: {
    fontSize: '14px',
  },
  headerCell: {
    fontSize: '14px',
  },
};

const searchKeys: (keyof TimelineDataItem)[] = [
  'index',
  'collectionDate',
  'tiledModelName',
  'orthotileName',
  'pointCloudName',
  'comment',
  'geodataName',
] as const;

function filterItems(items: TimelineDataItem[], searchText: string) {
  const text = searchText.trim().toLowerCase();
  if (text.length === 0) {
    return items;
  }

  return items.filter((item) =>
    searchKeys.some((x) => item[x]?.toString()?.toLocaleLowerCase().includes(text)),
  );
}

function isGeodataTimeline(container: SkyviewTimelineContainer) {
  const geodataIds = uniq([
    container.orthoTile?.geodata?.id,
    container.tiledModel?.geodata?.id,
    container.pointCloud?.geodata?.id,
  ]).filter(isDefined);

  return geodataIds.length === 1;
}

const TimelineList = () => {
  const { project } = React.useContext(ProjectContext);

  const { showToast } = useToast();

  const timelineConfigurationDialog = useDialog();
  const { t } = useTranslation();

  const [selectedTimelineContainer, setSelectedTimelineContainer] = React.useState<
    SkyviewTimelineContainer | undefined
  >();

  const [timelineContainers, setTimelineContainers] = useState(
    TimelineStore.instance.timelineContainers,
  );
  const [timelineList, setTimelineList] = useState<TimelineDataItem[]>([]);

  const hasGeodataText = useMemo(
    () => t('timelineList.columns.cloudProcess', { ns: 'components' }),
    [t],
  );
  const customTimelineText = useMemo(
    () => t('timelineList.columns.manualProcess', { ns: 'components' }),
    [t],
  );

  useEffect(() => {
    setTimelineList(
      timelineContainers.map((container) => ({
        collectionDate: container.collectionDate,
        id: container.id,
        index: container.index,
        comment: container.comment,
        orthotileName: container.orthoTile ? getArtifactDisplayName(container.orthoTile) : '-',
        pointCloudName: container.pointCloud ? getArtifactDisplayName(container.pointCloud) : '-',
        tiledModelName: container.tiledModel ? getArtifactDisplayName(container.tiledModel) : '-',
        geodataName: isGeodataTimeline(container) ? hasGeodataText : customTimelineText,
      })),
    );
  }, [timelineContainers, hasGeodataText, customTimelineText]);

  const stringOrDash = (name: string | undefined | null) => name ?? '-';
  const tableColumns: DataTableColumns<TimelineDataItem> = useMemo(
    () => ({
      id: { hidden: true },
      index: {
        unfilterable: true,
        title: t('timelineList.columns.nr', { ns: 'components' }),
        width: '4em',
      },
      collectionDate: {
        unfilterable: true,
        title: t('timelineList.columns.collectionDate', { ns: 'components' }),
        formatter: formattedDateTime,
      },
      geodataName: {
        unfilterable: true,
        title: t('timelineList.columns.source', { ns: 'components' }),
        formatter: stringOrDash,
      },
      tiledModelName: {
        unfilterable: true,
        title: t('geodata.artifacts.3dModel', { ns: 'common' }),
        formatter: stringOrDash,
      },
      pointCloudName: {
        unfilterable: true,
        title: t('geodata.artifacts.pointCloud', { ns: 'common' }),
        formatter: stringOrDash,
      },
      orthotileName: {
        unfilterable: true,
        title: t('geodata.artifacts.orthoPhoto', { ns: 'common' }),
        formatter: stringOrDash,
      },
      comment: {
        unfilterable: true,
        title: t('timelineList.columns.comment', { ns: 'components' }),
        formatter: stringOrDash,
      },
    }),
    [t],
  );

  const { mutateAsync: createTimelineMutateAsync, isPending: creatingProjectTimeline } =
    useCreateProjectTimeline({
      onSuccess: (result) => setTimelineContainers(TimelineStore.instance.push(result)),
    });
  const onTimelineAdded = async (options: OnCreateOptions) => {
    await createTimelineMutateAsync({
      path: { projectId: project.id },
      body: {
        date: options.dateTime.toISOString(),
        tiledModelId: options.tiledModelId,
        gltftScanId: options.gltftScanId,
        pointcloudId: options.pointCloudId,
        orthoTileId: options.orthoTileId,
        comment: options.comment,
      },
    });
  };

  const { mutateAsync: updateTimelineMutateAsync, isPending: updatingProjectTimeline } =
    useUpdateProjectTimeline({
      onSuccess: (result) => setTimelineContainers(TimelineStore.instance.update(result)),
    });

  const onTimelineUpdated = async (options: OnUpdateOptions) => {
    if (!isDefined(selectedTimelineContainer)) {
      return;
    }

    const diffOrUndefined = (a: string | null, b: string | null): string | undefined | null =>
      a !== b ? (isValidUuidV4(b ?? undefined) ? b : null) : undefined;

    await updateTimelineMutateAsync({
      containerId: selectedTimelineContainer.id,
      dateIsoString: options.dateTime?.toISOString(),
      tiledModelId: diffOrUndefined(
        selectedTimelineContainer.tiledModel?.type === 'tiled-model'
          ? selectedTimelineContainer.tiledModel.id
          : null,
        options.tiledModelId ?? null,
      ),
      scanId: diffOrUndefined(
        selectedTimelineContainer.tiledModel?.type === 'legacy-scan'
          ? selectedTimelineContainer.tiledModel.id
          : null,
        options.gltftScanId ?? null,
      ),
      pointCloudId: diffOrUndefined(
        selectedTimelineContainer.pointCloud?.id ?? null,
        options.pointCloudId ?? null,
      ),
      orthoTileId: diffOrUndefined(
        selectedTimelineContainer.orthoTile?.id ?? null,
        options.orthoTileId ?? null,
      ),
      comment:
        selectedTimelineContainer.comment !== (options.comment ?? '')
          ? options.comment ?? ''
          : undefined,
    });
  };

  const [searchText, setSearchText] = useState(
    sessionStorageUtils.getProjectItemOrDefault('timelineListText', ''),
  );
  const [items, setItems] = useState<TimelineDataItem[]>([]);
  useEffect(() => {
    setItems(filterItems(timelineList, searchText));
  }, [searchText, timelineList]);

  const onDeleteError = (error: Error) => {
    if (!axios.isAxiosError(error)) {
      return;
    }
    // This is not translated at this stage.
    // This does not follow the v1 standard of errors,
    // and updating the endpoint with correct error handling is out of scope.
    //
    //  So just mimic the previous behavior, as this has nothing to do with the React conversion.
    if (error.response?.data?.code === 'can_not_delete_timeline_with_connected_shared_views') {
      showToast({
        message: 'Tidslinjen går inte att ta bort eftersom den är kopplad till en delad vy.',
        type: 'error',
        title: 'Radera tidslinje',
      });
    }
  };

  const { mutateAsync: deleteTimelineMutateAsync, isPending: deletingProjectTimeline } =
    useDeleteProjectTimeline({
      onSuccess: (containerId) => setTimelineContainers(TimelineStore.instance.remove(containerId)),
      onError: onDeleteError,
    });
  const onTimelineDeleted = async (options: OnDeleteOptions) => {
    await deleteTimelineMutateAsync({
      path: { containerId: options.timelineId },
    });
    setSelectedTimelineContainer(undefined);
  };

  const resolveSelectedTimelineContainer = (item: TimelineDataItem) => {
    const timelineContainer = timelineContainers?.find((timeline) => timeline.id === item.id);
    setSelectedTimelineContainer(timelineContainer);
    timelineConfigurationDialog.show();
  };

  const isLoading = deletingProjectTimeline || creatingProjectTimeline || updatingProjectTimeline;

  return (
    <OverlayLoader visible={isLoading}>
      <Component className="notranslate" direction="column">
        <PageTitle text={t('timelineList.title', { ns: 'components' })} />
        <Stack alignItems="anchor-center" direction="row" justifyContent="space-between">
          <TextBox
            autoFocus={true}
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            width={250}
            onChange={(e) => {
              setSearchText(e.target.value);
              sessionStorageUtils.setProjectItem('timelineListText', e.target.value);
            }}
          />
          <Button
            color="primary"
            disabled={isLoading}
            variant="contained"
            onClick={() => timelineConfigurationDialog.show()}
          >
            {t('timelineList.addButton', { ns: 'components' })}
          </Button>
        </Stack>
        <DataTable
          columns={tableColumns}
          fixedLayout={true}
          items={items}
          pageSize={pageSize}
          tableStyle={tableStyle}
          onRowClicked={resolveSelectedTimelineContainer}
        />

        {/** When no items exist, display empty timeline content. */}
        {isLoading === false && timelineList.length === 0 && (
          <IconPanel
            header={
              <StyledCenter>
                <EmptyStateTimeline />
              </StyledCenter>
            }
            responsiveness={{ componentPxHeightBreak: 0 }}
          >
            {{
              title: t('timelineList.emptyTitle', { ns: 'components' }),
              info: (
                <StyledH5>
                  <Trans
                    components={{ bold: <strong /> }}
                    i18nKey="timelineList.emptyInfo"
                    ns="components"
                  />
                </StyledH5>
              ),
            }}
          </IconPanel>
        )}

        {timelineConfigurationDialog.render(
          <TimelineDialog
            dialog={timelineConfigurationDialog}
            timeline={selectedTimelineContainer}
            onClose={() => setSelectedTimelineContainer(undefined)}
            onCreate={onTimelineAdded}
            onDelete={onTimelineDeleted}
            onUpdate={onTimelineUpdated}
          />,
        )}
      </Component>
    </OverlayLoader>
  );
};

TimelineList.propTypes = {
  wrapWithAppAndProjectState: PropTypes.bool,
};

const Component = styled(Stack)``;

const StyledCenter = styled(Center)`
  margin: 2em;
  margin-top: 5em;

  width: 12em;
  height: 12em;

  svg {
    transform: scale(2);
  }
`;
const StyledH5 = styled.h5`
  margin-bottom: 8em;
  color: ${(props) => props.theme.color.gray.dark};
`;

export { TimelineList };
