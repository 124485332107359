import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { SkyView } from '../../../js/viewer/skyview';
import { usePreventPageUnload } from '../../hooks/use-prevent-page-unload';
import { AppContext } from '../../state/app-state';
import { defaultTheme } from '../../themes/default/styled-component/theme';
import { ChipStyled } from '../chip/chip';
import { LanguageProvider } from '../language-provider/language-provider';
import { PoweredBy } from '../powered-by/powered-by';
import { AsbuiltPanelStyled } from '../skyview/panels/asbuilt/asbuilt-panel';
import { RightMenu, RightMenuStyled } from '../skyview/right-menu/right-menu';
import { SkyviewState } from '../skyview/skyview-state';
import { WmsAttributions } from '../wms-map/wms-attributions';
import { AssetMenu } from './asset-menu/asset-menu';
import {
  CollapsableContainer,
  CollapsableContainerStyled,
} from './asset-menu/collapsable-container';
import { CadSideMenu, CadSideMenuStyled } from './cad-side-menu';
import {
  AssetPointsPanel,
  AssetPointsPanelStyled,
} from './components/asset-points-panel/asset-points-panel';
import { ShareViewStyled } from './components/share-view/share-view';
import { WmsSkyViewBridge } from './components/wms-skyview-bridge';
import { SkyviewChipsContainer } from './skyview-chips-container';
import { Toolbar, ToolbarStyled } from './toolbar/toolbar';

type Props = {
  skyView: SkyView;
  assetMenuVisible?: boolean;
};

const Skyview = (props: Props) => {
  usePreventPageUnload();

  const collapedContainerWidth = 410;
  const { isMobileApplication } = React.useContext(AppContext);
  return (
    <LanguageProvider>
      <ThemeProvider theme={defaultTheme}>
        <SkyviewState skyview={props.skyView}>
          {
            // Placing outside of Component because it blocks all pointer events, disabling interaction with the WMS-map
            <WmsSkyViewBridge />
          }
          {isMobileApplication() || (
            <Component assetMenuVisible={props.assetMenuVisible}>
              {props.assetMenuVisible !== false && (
                <CollapsableContainer width={collapedContainerWidth}>
                  <AssetMenu />
                </CollapsableContainer>
              )}
              <Center>
                <WmsAttributions />
                {props.skyView.sharedView || <SkyviewChipsContainer />}
                {<AssetPointsPanel />}
                {props.skyView.isCadViewerSceneActive() && <CadSideMenu />}
                <PoweredBy dx={80} dy={10} position={'absolute-bottom-right'} />
                <Toolbar skyView={props.skyView} />
              </Center>
              <RightMenu skyView={props.skyView} />
            </Component>
          )}
        </SkyviewState>
      </ThemeProvider>
    </LanguageProvider>
  );
};

Skyview.propTypes = {
  wrapWithAppState: PropTypes.bool,
  skyView: PropTypes.object.isRequired,
  assetMenuVisible: PropTypes.bool,
};

// Component position absolute in order to render above skyview.
// This is why pointer-events is set to let through events to skyview.
//
// Also, give each child a new stacking context (z-index auto). This makes the tooltip, when
// hovering a toolbar icon, to be rendered above the asset menu.
const Component = styled.div<{ assetMenuVisible?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => `${props.assetMenuVisible === false ? '' : 'auto '}1fr auto;`}
  grid-template-rows: 100%;
  pointer-events: none;

  ${CollapsableContainerStyled},
  ${ToolbarStyled},
  ${RightMenuStyled},
  ${ChipStyled},
  ${CadSideMenuStyled},
  ${AsbuiltPanelStyled},
  ${ShareViewStyled},
  ${AssetPointsPanelStyled} {
    pointer-events: all;
    isolation: isolate;
  }
`;

const Center = styled.div`
  position: relative;
`;

export { Skyview };
