import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileModel } from '../../../../../../../typings/api/skymap/rest/v1/.common';
import { CreatePoiParameters } from '../../../../../../../typings/api/skymap/rest/v1/project';
import { SkyMapAxiosServiceFactory } from '../../../../../../js/services/axios/skymap-axios-service-factory';
import { RequestParamsToApiPaths } from '../../../../../../js/utils/typescript-utils';
import { isDefined } from '../../../../../../js/utils/variables';
import { AssetManager } from '../../../../../../js/viewer/asset-manager';
import { CoordinateManager } from '../../../../../../js/viewer/coordinate-manager';
import { Poi, PoiProperties } from '../../../../../../js/viewer/elements/poi';
import { errorUtils, getResponseErrorParameterValue } from '../../../../../api/error-handling';
import { useDefaultAssetName } from '../../../../../hooks/use-default-asset-name';
import { useDialog } from '../../../../../hooks/use-dialog';
import { useErrorHandling } from '../../../../../hooks/use-error-handling';
import { getSelectedProjectId } from '../../../../../routing/utils';
import { Button } from '../../../../button/button';
import { Dialog } from '../../../../dialog/dialog';
import { FileManagerSelectionDialog } from '../../../../file-manager/dialogs/file-manager-selection-dialog/file-manager-selection-dialog';
import { InfoBox } from '../../../../info-box/info-box';
import { LabelledContainer } from '../../../../labelled-container/labelled-container';
import { Stack } from '../../../../stack/stack';
import { TextArea } from '../../../../text-area/text-area';
import { TextBox } from '../../../../text-box/text-box';
import { PoiFileListFileModel, PoiFilesList } from './poi-files-list';

export type NewPoiContext = {
  type: 'NEW';
  localPosition: THREE.Vector3;
};

export type ExistingPoiContext = {
  type: 'EXISTING';
  poi: Poi;
};

export type PoiContext = NewPoiContext | ExistingPoiContext;

interface Props {
  context: PoiContext;

  /**
   * To be used when caller is a React component.
   */
  onClose?: (saved: boolean) => void;
}

enum Status {
  Idle,
  Loading,
  LoadingError,
  Saving,
  SavingError,
}

type ApiRequestPath = RequestParamsToApiPaths<CreatePoiParameters>;

const PoiDialog = (props: Props) => {
  const { t } = useTranslation();
  const saveButtonRef = React.useRef<HTMLButtonElement>(null);
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);

  const properties = props.context.type === 'EXISTING' ? props.context.poi.properties : undefined;

  const [files, setFiles] = React.useState<PoiFileListFileModel[]>([]);
  const { handleError, getErrorForPath, clearErrorForPath } = useErrorHandling<ApiRequestPath>();
  const [status, setStatus] = React.useState(Status.Idle);
  const [title, setTitle] = React.useState(properties?.title ?? '');
  const [text, setText] = React.useState(properties?.comment ?? '');

  const defaultAssetName = useDefaultAssetName('poi');

  const fileManagerDialog = useDialog();

  const close = (saved: boolean) => {
    props.onClose?.(saved);
  };

  const load = React.useCallback(async () => {
    const poi = props.context.type === 'EXISTING' ? props.context.poi : undefined;
    if (!isDefined(poi)) {
      return;
    }

    setStatus(Status.Loading);

    try {
      const { data: poiFiles } = await SkyMapAxiosServiceFactory.instance
        .createPoiServiceV1()
        .getPoiAssociateFiles({
          path: {
            poiId: poi.assetId!,
          },
        });
      setTitle(poi.properties.title);
      setText(poi.properties.comment ?? '');
      setFiles(poiFiles);
      setStatus(Status.Idle);
    } catch (err) {
      if (!axios.isCancel(err)) {
        handleError(err);
        setStatus(Status.LoadingError);
      }
    }
  }, [props.context, handleError]);

  React.useEffect(() => {
    void load();
  }, [load, props.context]);

  const save = async () => {
    try {
      setStatus(Status.Saving);
      if (props.context.type === 'NEW') {
        await create(props.context.localPosition);
      } else if (props.context.type === 'EXISTING') {
        await update(props.context.poi);
      }

      AssetManager.instance.publishAssetListChanged();
      close(true);
    } catch (err) {
      setStatus(Status.SavingError);

      if (!axios.isCancel(err)) {
        handleError(err);

        // Handle error when files are not found.
        errorUtils.isResponseError(
          err,
          'resource_not_found',
          'file:not_found_multiple',
          (errorItem) => {
            const notFoundFileIds = getResponseErrorParameterValue<string[]>(errorItem, 'fileIds');

            // Mark all files not found as deleted.
            setFiles(
              files.map((x) =>
                notFoundFileIds?.includes(x.id)
                  ? {
                      ...x,
                      markAsDeleted: true,
                    }
                  : x,
              ),
            );
          },
        );
      }
    }
  };

  const update = async (poi: Poi) => {
    const [{ data: updatedPoi }] = await Promise.all([
      SkyMapAxiosServiceFactory.instance.createPoiServiceV1().patchPoi({
        body: {
          title,
          comment: text ?? null,
        },
        path: {
          poiId: poi.assetId!,
        },
      }),
      SkyMapAxiosServiceFactory.instance.createPoiServiceV1().associateFilesWithPoi({
        body: {
          fileIds: files.map((file) => file.id),
        },
        path: {
          poiId: poi.assetId!,
        },
      }),
    ]);

    poi.onSaved({
      ...updatedPoi,
      visible: false,
      isUpdated: true,
    } satisfies PoiProperties);
  };

  const create = async (position: THREE.Vector3) => {
    const wgs84Position = CoordinateManager.getInstance().localToWgs84Coords(position);

    // Create a POI along with a comment (both represented by dynamic asset for now).
    const { data: newPoi } = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV1()
      .createPoi({
        body: {
          wgs84Latitude: wgs84Position.latitude,
          wgs84Longitude: wgs84Position.longitude,
          metersAboveMeanSeaLevel: wgs84Position.height,
          title,
          comment: text || undefined,
        },
        path: {
          projectId: getSelectedProjectId()!,
        },
      });

    await SkyMapAxiosServiceFactory.instance.createPoiServiceV1().associateFilesWithPoi({
      body: {
        fileIds: files.map((file) => file.id),
      },
      path: {
        poiId: newPoi.id,
      },
    });

    const poiAsset = new Poi(newPoi as PoiProperties, undefined);

    AssetManager.instance.addAsset(poiAsset);
    AssetManager.instance.select(poiAsset);
  };

  const addFiles = (newFiles: FileModel[]) => {
    const filesToAdd = newFiles.filter((file) => !files.some((x) => x.id === file.id));

    const newState = [...files, ...filesToAdd];
    newState.sort((a, b) => a.name.localeCompare(b.name));
    setFiles(newState);
  };

  const renderContent = () => {
    switch (status) {
      case Status.Loading:
        return renderLoading();
      case Status.LoadingError:
        return renderLoadingError();
      case Status.Idle:
      case Status.Saving:
      case Status.SavingError:
        return renderIdle();
    }
  };

  const renderLoading = () => {
    return <InfoBox color="yellow">{t('oneMoment', { ns: 'common' })}</InfoBox>;
  };

  const renderLoadingError = () => {
    return <InfoBox color="red">{t('poiDialog.couldNotFetchPoi', { ns: 'components' })}</InfoBox>;
  };

  const renderIdle = () => {
    return (
      <Stack spacing={1}>
        <LabelledContainer text={t('poiDialog.title', { ns: 'components' })}>
          {(formElementId) => (
            <TextBox
              autoFocus={true}
              disabled={status === Status.Saving}
              errorMessage={getErrorForPath('body.title')}
              id={formElementId()}
              placeholder={defaultAssetName}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                clearErrorForPath('body.title');
              }}
            />
          )}
        </LabelledContainer>
        <LabelledContainer text={t('poiDialog.text', { ns: 'components' })}>
          {(formElementId) => (
            <TextArea
              disabled={status === Status.Saving}
              errorMessage={getErrorForPath('body.comment')}
              id={formElementId()}
              rows={5}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                clearErrorForPath('body.comment');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              }}
            />
          )}
        </LabelledContainer>
        <LabelledContainer text={t('commentDialog.files', { ns: 'components' })}>
          {files.some((x) => x.markAsDeleted) && (
            <InfoBox bottomMargin={true} color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
              {t('commentDialog.filesNoLongerExist', { ns: 'components' })}
            </InfoBox>
          )}

          <TableContainer>
            {files.length === 0 ? (
              <InfoBox color="yellow">
                {t('commentDialog.noFilesChoosen', { ns: 'components' })}
              </InfoBox>
            ) : (
              <PoiFilesList
                files={files}
                showRemoveIcon={true}
                onFileRemoved={(file) => {
                  setFiles(files.filter((x) => x.id !== file.id));
                }}
              />
            )}
          </TableContainer>
        </LabelledContainer>
      </Stack>
    );
  };

  const disableButtons = [Status.Loading, Status.Saving].includes(status);

  return (
    <>
      <Dialog
        closeButtonRef={closeButtonRef}
        closeIcon={false}
        closeOnDimmerClick={false}
        confirmButtonRef={saveButtonRef}
        maxHeight={false}
        width={400}
        onClose={() => close(false)}
      >
        {{
          header: t('poiDialog.titleNew', { ns: 'components' }),
          content: renderContent(),
          footer: {
            left: (
              <>
                <Button
                  color="secondary"
                  disabled={disableButtons}
                  variant="contained"
                  onClick={() => {
                    fileManagerDialog.show();
                  }}
                >
                  {t('commentDialog.chooseFiles', { ns: 'components' })}
                </Button>
              </>
            ),
            right: (
              <>
                <Button
                  color="primary"
                  disabled={disableButtons}
                  loading={status === Status.Saving}
                  ref={saveButtonRef}
                  variant="contained"
                  onClick={save}
                >
                  {t('save', { ns: 'common' })}
                </Button>
                <Button
                  disabled={status === Status.Saving}
                  ref={closeButtonRef}
                  variant="text"
                  onClick={() => close(false)}
                >
                  {t('cancel', { ns: 'common' })}
                </Button>
              </>
            ),
          },
        }}
      </Dialog>

      {fileManagerDialog.render(
        <FileManagerSelectionDialog
          selectionMode="CheckFiles"
          onCancel={() => {
            fileManagerDialog.hide();
          }}
          onSelect={(folders, files) => {
            addFiles(files);
            fileManagerDialog.hide();
          }}
        />,
      )}
    </>
  );
};

const TableContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overscroll-behavior: contain;
`;

PoiDialog.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
  poi: PropTypes.any,
};

export { PoiDialog };
