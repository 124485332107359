import React, { Reducer, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { formattedDateTime } from '../../../../../../../../js/utils/dateUtils';
import { isValidNumber } from '../../../../../../../../js/utils/math/number';
import { copyMap, isDefined, mapToArray } from '../../../../../../../../js/utils/variables';
import { RevertChange } from '../../../../../../../../js/viewer/elements/design/design-node/design-node';
import {
  AttributeGroupEntry,
  AttributeId,
  getUnitType,
  SkyMapAttribute,
  ValueId,
} from '../../../../../../../../js/viewer/elements/design/skymap-extras';
import { DesignTool } from '../../../../../../../../js/viewer/tools/design-tool';
import { addAndCommitAction } from '../../../../../../../../js/viewer/tools/utils/context-menu/design-tool/utils/actions';
import {
  AttributeDataWithId,
  clearAttributesFromSelection,
  getMatchingSelection,
  setAttributesOnSelection,
} from '../../../../../../../../js/viewer/tools/utils/context-menu/design-tool/utils/attributes';
import {
  applySelectionResult,
  deselect,
  SelectionResult,
} from '../../../../../../../../js/viewer/tools/utils/context-menu/design-tool/utils/select';
import { DateTimeInput } from '../../../../../../date-time-input/date-time-input';
import { Icon } from '../../../../../../icon/icon';
import { InfoBox } from '../../../../../../info-box/info-box';
import { LabelledContainer } from '../../../../../../labelled-container/labelled-container';
import { NoTranslateSpan } from '../../../../../../no-translate/no-translate';
import { OrderedList } from '../../../../../../ordered-list/ordered-list';
import { Select } from '../../../../../../select/select';
import { Stack } from '../../../../../../stack/stack';
import { TextBox } from '../../../../../../text-box/text-box';
import { TooltipInfoIcon } from '../../../../../../tooltip-info-icon/tooltip-info-icon';
import { useAttributes } from '../../hooks/use-attributes';
import { useCurrentSelection } from '../../hooks/use-current-selection';

type Props = {
  tool: DesignTool;
};

type DispatchItem = (
  | {
      type: 'remove';
      data: AttributeId[];
    }
  | {
      type: 'upsert';
      data: AttributeDataWithId[];
    }
  | {
      type: 'clear';
    }
) & { tool: DesignTool };

function reducer(state: AttributeDataWithId[], action: DispatchItem): AttributeDataWithId[] {
  if (action.type === 'clear') {
    action.tool.activeAttributes = new Map();
    return [];
  }

  if (action.type === 'upsert') {
    for (const item of action.data) {
      const index = state.findIndex((x) => x.id === item.id);
      if (index < 0) {
        state.push(item);
      } else {
        state[index] = item;
      }
      action.tool.activeAttributes.set(item.id, item);
    }
  }

  if (action.type === 'remove') {
    state = state.filter((x) => !action.data.includes(x.id));
    for (const id of action.data) {
      action.tool.activeAttributes.delete(id);
    }
  }

  return state.slice();
}

export const SetAttributes = (props: Props) => {
  const { t } = useTranslation();
  const [items, dispatchItem] = React.useReducer<Reducer<AttributeDataWithId[], DispatchItem>>(
    reducer,
    [],
  );

  const theme = useTheme();
  const [applyOnNewPoints, setApplyOnNewPoints] = useState(props.tool.applyAttributesOnNewPoints);
  const [selectedAttributeId, setSelectedAttributeId] = useState<string>();
  const { attributes, attributeGroups } = useAttributes(props.tool);
  const { currentSelection, multiSelectInProgress } = useCurrentSelection(props.tool);
  const [activeAttributeGroup, setActiveAttributeGroup] = useState<
    { name: string; entries: AttributeGroupEntry[] } | undefined
  >(props.tool.activeAttributeGroup);
  const [activeAttributeGroupRowId, setActiveAttributeGroupRowId] = useState<string | undefined>(
    props.tool.activeAttributeGroupRowId,
  );
  const [remainingAttributes, setRemainingAttributes] = useState<
    { id: string; name: string; isGroup: boolean }[]
  >([]);

  function updateActiveAttributeGroup(
    value: { name: string; entries: AttributeGroupEntry[] } | undefined,
  ) {
    setActiveAttributeGroup(value);
    props.tool.activeAttributeGroup = value;
  }

  function updateActiveAttributeGroupRowId(value: string | undefined) {
    setActiveAttributeGroupRowId(value);
    props.tool.activeAttributeGroupRowId = value;
  }

  function clearActiveAttributeGroup() {
    if (isDefined(activeAttributeGroup)) {
      dispatchItem({
        type: 'remove',
        data: [...activeAttributeGroup.entries[0].data.keys()],
        tool: props.tool,
      });
      updateActiveAttributeGroup(undefined);
    }
  }

  useEffect(() => {
    dispatchItem({
      type: 'upsert',
      data: [...props.tool.activeAttributes].map((entry) => {
        return {
          id: entry[0],
          ...entry[1],
        };
      }),
      tool: props.tool,
    });
  }, [props.tool.activeAttributes, props.tool]);

  useEffect(() => {
    const remaining = attributes
      .filter((x) => !isDefined(items.find((y) => y.id === x.id)))
      .map((value) => {
        return {
          id: value.id.toString(),
          name: value.name,
          isGroup: false,
        };
      });
    const removedAttributes = [...props.tool.activeAttributes].filter(
      (attribute) => !attributes.some((x) => x.id === attribute[0]),
    );

    remaining.unshift(
      ...attributeGroups
        .filter((x) =>
          isDefined(activeAttributeGroup) ? x.name !== activeAttributeGroup.name : true,
        )
        .map((x) => {
          return {
            id: x.name,
            name: `Kodlista: ${x.name}`,
            isGroup: true,
          };
        }),
    );

    for (const attribute of removedAttributes) {
      dispatchItem({
        type: 'remove',
        data: [attribute[0]],
        tool: props.tool,
      });
    }

    setRemainingAttributes(remaining);
    setSelectedAttributeId(remaining.at(0)?.id);
  }, [props.tool, items, attributes, attributeGroups, activeAttributeGroup]);

  return attributes.length === 0 ? (
    <InfoBox color="yellow" leftIcon={{ icon: 'info-circle' }}>
      {t('section.attributes.setAttributes.content.noAttributesInfo', { ns: 'skyviewDesign' })}
    </InfoBox>
  ) : (
    <Stack spacing={0.8}>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <TooltipInfoIcon
          content={
            <OrderedList
              items={[
                t('section.attributes.setAttributes.content.infoIcon.item1', {
                  ns: 'skyviewDesign',
                }),
                t('section.attributes.setAttributes.content.infoIcon.item2', {
                  ns: 'skyviewDesign',
                }),
                t('section.attributes.setAttributes.content.infoIcon.item3', {
                  ns: 'skyviewDesign',
                }),
                t('section.attributes.setAttributes.content.infoIcon.item4', {
                  ns: 'skyviewDesign',
                }),
              ]}
            />
          }
          placement="left"
          title={t('section.attributes.setAttributes.content.infoIcon.tooltip', {
            ns: 'skyviewDesign',
          })}
        />

        {applyOnNewPoints ? (
          <Icon
            color={theme.color.red}
            fixedWidth={true}
            icon={['fad', 'circle-stop']}
            mouseDownStyle={{ opacity: 0.5 }}
            title={t('section.attributes.setAttributes.content.applyOnPointsIconEnabled.tooltip', {
              ns: 'skyviewDesign',
            })}
            onClick={() => {
              setApplyOnNewPoints(false);
              props.tool.applyAttributesOnNewPoints = false;
            }}
            onHoverStyle={{ icon: ['fas', 'circle-stop'] }}
          />
        ) : (
          <Icon
            color={theme.color.green}
            fixedWidth={true}
            icon={['fad', 'circle-play']}
            mouseDownStyle={{ opacity: 0.5 }}
            title={t('section.attributes.setAttributes.content.applyOnPointsIconDisabled.tooltip', {
              ns: 'skyviewDesign',
            })}
            onClick={() => {
              setApplyOnNewPoints(true);
              props.tool.applyAttributesOnNewPoints = true;
            }}
            onHoverStyle={{ icon: ['fas', 'circle-play'] }}
          />
        )}

        <Icon
          fixedWidth={true}
          icon={['fal', 'magnifying-glass']}
          mouseDownStyle={{ opacity: 0.5 }}
          title={t('section.attributes.setAttributes.content.selectObjectIcon.tooltip', {
            ns: 'skyviewDesign',
          })}
          onClick={() => {
            deselect(props.tool);
            const selection = getMatchingSelection(props.tool.asset.designNode, items);
            deselect(props.tool);
            applySelectionResult(props.tool, selection);
          }}
          onHoverStyle={{ icon: ['fad', 'magnifying-glass'] }}
        />

        <Icon
          disabled={!multiSelectInProgress}
          fixedWidth={true}
          icon={['fal', 'pen-to-square']}
          mouseDownStyle={{ opacity: 0.5 }}
          title={t('section.attributes.setAttributes.content.updateObjectIcon.tooltip', {
            ns: 'skyviewDesign',
          })}
          onClick={() => {
            let changes: RevertChange[] = [];
            const attributes = copyMap(props.tool.activeAttributes, { deep: true });
            const selection: SelectionResult = {
              points: [...currentSelection.points],
              lineSegments: [...currentSelection.lineSegments],
              faces: [...currentSelection.faces],
            };

            addAndCommitAction(props.tool, {
              commit: () => {
                changes = setAttributesOnSelection(props.tool, selection, attributes);
                props.tool.asset.designNode.refresh();
                deselect(props.tool);
              },
              rollback: () => {
                props.tool.asset.designNode.revert(changes);
                props.tool.asset.designNode.refresh();
              },
            });
          }}
          onHoverStyle={{ icon: ['fad', 'pen-to-square'] }}
        />

        <Icon
          disabled={!multiSelectInProgress}
          fixedWidth={true}
          icon={['fal', 'eraser']}
          mouseDownStyle={{ opacity: 0.5 }}
          title={t('section.attributes.setAttributes.content.clearAllAttributesIcon.tooltip', {
            ns: 'skyviewDesign',
          })}
          onClick={() => {
            let changes: RevertChange[] = [];
            const selection: SelectionResult = {
              points: [...currentSelection.points],
              lineSegments: [...currentSelection.lineSegments],
              faces: [...currentSelection.faces],
            };

            addAndCommitAction(props.tool, {
              commit: () => {
                changes = clearAttributesFromSelection(props.tool, selection);
                props.tool.asset.designNode.refresh();
                deselect(props.tool);
              },
              rollback: () => {
                props.tool.asset.designNode.revert(changes);
                props.tool.asset.designNode.refresh();
              },
            });
          }}
          onHoverStyle={{ icon: ['fad', 'eraser'] }}
        />
      </Stack>

      <NoTranslateSpan>
        <Stack alignItems={'center'} direction="row" padding="0 0.3em 0.3em 0" spacing={0.2}>
          <Select
            options={remainingAttributes}
            padding={0.2}
            placeholderText={t('section.attributes.setAttributes.content.noAttributeLeft', {
              ns: 'skyviewDesign',
            })}
            value={selectedAttributeId}
            onChange={(e) => {
              setSelectedAttributeId(e.target.value);
            }}
          />
          <Icon
            disabled={remainingAttributes.length === 0}
            fixedWidth={true}
            icon={['fal', 'plus']}
            mouseDownStyle={{ opacity: 0.5 }}
            title={t('section.attributes.setAttributes.content.addAttributeIcon.tooltip', {
              ns: 'skyviewDesign',
            })}
            onClick={() => {
              const selection = remainingAttributes.find((x) => x.id === selectedAttributeId);
              if (selection?.isGroup) {
                const group = attributeGroups.find((x) => x.name === selection.id);

                clearActiveAttributeGroup();

                if (isDefined(group)) {
                  const data: AttributeDataWithId[] = [];
                  for (const [attributeId, attributeData] of group.entries[0].data.entries()) {
                    data.push({
                      id: attributeId,
                      ...attributeData,
                    });
                  }

                  updateActiveAttributeGroup(group);
                  updateActiveAttributeGroupRowId('0');
                  dispatchItem({ type: 'upsert', data, tool: props.tool });
                }

                return;
              }

              const newAttribute = attributes.find(
                (x) =>
                  x.name === remainingAttributes.find((y) => y.id === selectedAttributeId)?.name,
              );

              if (
                !isDefined(newAttribute) ||
                isDefined(items.find((x) => x.id === newAttribute.id))
              ) {
                throw Error('Value with index already exists.');
              }

              const newItem: AttributeDataWithId =
                newAttribute.type === 'enum'
                  ? {
                      type: 'enum',
                      id: newAttribute.id,
                      index: [...newAttribute.valueMap][0][0],
                    }
                  : newAttribute.type === 'number'
                    ? newAttribute.unit === getUnitType('timestamp')
                      ? {
                          type: 'number',
                          id: newAttribute.id,
                          value: new Date().getTime(),
                        }
                      : {
                          type: 'number',
                          id: newAttribute.id,
                          value: 0,
                        }
                    : {
                        type: 'string',
                        id: newAttribute.id,
                        value: '',
                      };

              dispatchItem({ type: 'upsert', data: [newItem], tool: props.tool });
              setSelectedAttributeId(undefined);
            }}
            onHoverStyle={{ icon: ['fad', 'plus'] }}
          />
          <Icon
            disabled={items.length === 0}
            fixedWidth={true}
            icon={['fal', 'ban']}
            mouseDownStyle={{ opacity: 0.5 }}
            title={t('section.attributes.setAttributes.content.clearAttributeListIcon.tooltip', {
              ns: 'skyviewDesign',
            })}
            onClick={() => {
              dispatchItem({ type: 'clear', tool: props.tool });
              updateActiveAttributeGroup(undefined);
              setSelectedAttributeId(undefined);
            }}
            onHoverStyle={{ icon: ['fad', 'ban'] }}
          />
        </Stack>
      </NoTranslateSpan>

      <NoTranslateSpan>
        {isDefined(activeAttributeGroup) && (
          <LabelledContainer text={activeAttributeGroup.name}>
            <Stack direction="row" spacing={0.2}>
              <Select
                options={activeAttributeGroup.entries.map((x, idx) => {
                  return {
                    id: idx.toString(),
                    name: x.displayName,
                  };
                })}
                padding={0.2}
                value={activeAttributeGroupRowId}
                onChange={(e) => {
                  const id = e.target.value;
                  updateActiveAttributeGroupRowId(id);
                  const data: AttributeDataWithId[] = [
                    ...activeAttributeGroup.entries[Number(id)].data,
                  ].map((x) => {
                    return {
                      id: x[0],
                      ...x[1],
                    };
                  });
                  dispatchItem({ type: 'upsert', data, tool: props.tool });
                }}
              />

              <Icon
                fixedWidth={true}
                icon={['fal', 'trash-alt']}
                mouseDownStyle={{ opacity: 0.5 }}
                title={t(
                  'section.attributes.setAttributes.content.removeAttributeFromList.tooltip',
                  { ns: 'skyviewDesign' },
                )}
                onClick={() => clearActiveAttributeGroup()}
                onHoverStyle={{ icon: ['fad', 'trash-alt'] }}
              />
            </Stack>
          </LabelledContainer>
        )}
      </NoTranslateSpan>

      <NoTranslateSpan>
        {items.map((item, idx) => (
          <SetAttributeRow
            attribute={attributes.find((x) => x.id === item.id)}
            disabled={isDefined(activeAttributeGroup?.entries[0].data.get(item.id))}
            dispatchItem={dispatchItem}
            item={item}
            key={idx}
            tool={props.tool}
          />
        ))}
      </NoTranslateSpan>
    </Stack>
  );
};

type SetAttributeRowProps = {
  tool: DesignTool;
  item: AttributeDataWithId;
  attribute?: SkyMapAttribute;
  disabled: boolean;
  dispatchItem: React.Dispatch<DispatchItem>;
};

const SetAttributeRow = (props: SetAttributeRowProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'apply' | 'remove'>(
    props.item.clearAttribute ? 'remove' : 'apply',
  );

  if (!isDefined(props.attribute)) {
    return <></>;
  }

  const apply = mode === 'apply';
  const isTimeStamp =
    props.attribute.type === 'number' && props.attribute.unit === getUnitType('timestamp');

  return (
    <LabelledContainer text={props.attribute.name}>
      <Stack direction="column" spacing={0.2}>
        {props.item.type === 'enum' &&
          props.tool.asset.designNode.colorCoding.attributeId === props.item.id && (
            <Stack alignItems="center" direction="row" spacing={0.2}>
              <ColorIcon
                data-color={
                  props.tool.asset.designNode
                    .getEnumAttribute(props.item.id)
                    .colorMap.get(props.item.index)
                    ?.toArray() ?? [0, 0, 0]
                }
              />
              <Label>
                {t('section.attributes.setAttributes.content.attributeIsUsedForColorCoding', {
                  ns: 'skyviewDesign',
                })}
              </Label>
              <FlexGrow />
            </Stack>
          )}

        <Stack alignItems={'center'} direction="row" padding="0 0.3em 0.3em 0" spacing={0.2}>
          <InputContainer>
            {apply && props.item.type === 'string' && (
              <TextBox
                defaultValue={props.item.value.toString()}
                disabled={props.disabled}
                padding={0.2}
                onChange={(e) => {
                  if (props.item.type === 'string') {
                    props.dispatchItem({
                      type: 'upsert',
                      data: [
                        {
                          type: 'string',
                          id: props.item.id,
                          value: e.target.value,
                        },
                      ],
                      tool: props.tool,
                    });
                  }
                }}
              />
            )}

            {apply && props.item.type === 'number' && !isTimeStamp && (
              <TextBox
                defaultValue={props.item.value.toString()}
                disabled={props.disabled}
                padding={0.2}
                type="number"
                onChange={(e) => {
                  const number = Number(e.target.value);
                  if (isValidNumber(number) && props.item.type === 'number') {
                    props.dispatchItem({
                      type: 'upsert',
                      data: [
                        {
                          type: 'number',
                          id: props.item.id,
                          value: number,
                        },
                      ],
                      tool: props.tool,
                    });
                  }
                }}
              />
            )}
            {apply && props.item.type === 'number' && isTimeStamp && (
              <DateTimeInput
                collapseOnEmpty={true}
                dateFormatter={formattedDateTime}
                disabled={props.disabled}
                displayTime={true}
                initialValue={new Date(props.item.value)}
                padding={0.2}
                onDateChoosen={(date) => {
                  if (props.item.type === 'number') {
                    props.dispatchItem({
                      type: 'upsert',
                      data: [
                        {
                          type: 'number',
                          id: props.item.id,
                          value: date.getTime(),
                        },
                      ],
                      tool: props.tool,
                    });
                  }
                }}
              />
            )}

            {apply && props.item.type === 'enum' && (
              <Select
                disabled={props.disabled}
                options={mapToArray(
                  props.tool.asset.designNode.getEnumAttribute(props.item.id).valueMap,
                ).map((x) => {
                  return { id: x[0].toString(), name: x[1] };
                })}
                padding={0.2}
                value={props.item.index.toString()}
                onChange={(e) => {
                  const index = Number(e.target.value) as ValueId;
                  if (isValidNumber(index) && props.item.type === 'enum') {
                    props.dispatchItem({
                      type: 'upsert',
                      data: [
                        {
                          type: 'enum',
                          id: props.item.id,
                          index: index,
                        },
                      ],
                      tool: props.tool,
                    });
                  }
                }}
              />
            )}

            {!apply && (
              <TextBox
                disabled={true}
                padding={0.2}
                placeholder={t('section.attributes.setAttributes.content.clearAttribute', {
                  ns: 'skyviewDesign',
                })}
              />
            )}
          </InputContainer>

          {apply ? (
            <Icon
              disabled={props.disabled}
              fixedWidth={true}
              icon={['fal', 'circle-minus']}
              mouseDownStyle={{ opacity: 0.5 }}
              title={t('section.attributes.setAttributes.content.clearAttributeIcon.tooltip', {
                ns: 'skyviewDesign',
              })}
              onClick={() => {
                setMode('remove');
                props.dispatchItem({
                  type: 'upsert',
                  data: [
                    {
                      ...props.item,
                      clearAttribute: true,
                    },
                  ],
                  tool: props.tool,
                });
              }}
              onHoverStyle={{ icon: ['fad', 'circle-minus'] }}
            />
          ) : (
            <Icon
              disabled={props.disabled}
              fixedWidth={true}
              icon={['fal', 'circle-plus']}
              mouseDownStyle={{ opacity: 0.5 }}
              title={t('section.attributes.setAttributes.content.applyAttributeIcon.tooltip', {
                ns: 'skyviewDesign',
              })}
              onClick={() => {
                setMode('apply');
                props.dispatchItem({
                  type: 'upsert',
                  data: [
                    {
                      ...props.item,
                      clearAttribute: undefined,
                    },
                  ],
                  tool: props.tool,
                });
              }}
              onHoverStyle={{ icon: ['fad', 'circle-plus'] }}
            />
          )}

          <Icon
            disabled={props.disabled}
            fixedWidth={true}
            icon={['fal', 'trash-alt']}
            mouseDownStyle={{ opacity: 0.5 }}
            title={t('section.attributes.setAttributes.content.removeAttributeFromList.tooltip', {
              ns: 'skyviewDesign',
            })}
            onClick={() =>
              props.dispatchItem({ type: 'remove', data: [props.item.id], tool: props.tool })
            }
            onHoverStyle={{ icon: ['fad', 'trash-alt'] }}
          />
        </Stack>
      </Stack>
    </LabelledContainer>
  );
};

const InputContainer = styled.div`
  flex-grow: 1;

  * > {
    width: 100%;
  }
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const Label = styled.div`
  color: ${(props) => props.theme.color.gray.dark};
  font-style: italic;
  font-size: 0.85em;
`;

const ColorIcon = styled.div<{ 'data-color': number[] }>`
  margin: auto;
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.2em;
  border: 0.1em solid ${(props) => props.theme.color.gray.darkest};
  background-color: ${(props) =>
    `rgb(${props['data-color'].map((x) => Math.floor(x * 255)).join(',')})`};
`;
