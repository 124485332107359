import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { FileModel } from '../../../../../../../typings/api/skymap/rest/v1/.common';
import { filenameToFontAwesomeIcon } from '../../../../../../js/utils/io/file';
import { canFileBeOpenedInline } from '../../../../../utils/file';
import { DownloadFileIcon } from '../../../../file-manager/download-file-icon';
import { Icon } from '../../../../icon/icon';
import { Table } from '../../../../table/table';

export interface PoiFileListFileModel extends FileModel {
  markAsDeleted?: boolean;
}

type Props = {
  files: PoiFileListFileModel[];
  showRemoveIcon: boolean;
  onFileRemoved?: (file: FileModel) => void;
};

const PoiFilesList = (props: Props) => {
  const { t } = useTranslation();
  const anyFilesInlineOpenable = props.files.some((x) => canFileBeOpenedInline(x.name));

  return (
    <Table>
      <Table.Body>
        {props.files.map((x) => {
          const { icon, color } = filenameToFontAwesomeIcon(x.name);
          return (
            <Table.Row key={x.id}>
              <Table.Cell padding={0.2} shrink={true}>
                <Icon
                  color={x.markAsDeleted ? '#b22222' : color}
                  data-testid="icon-file"
                  fixedWidth={true}
                  icon={['fad', x.markAsDeleted ? 'exclamation' : icon]}
                />
              </Table.Cell>
              <Table.Cell padding={0.5} wrapping={'anywhere'}>
                <Name markAsDeleted={x.markAsDeleted ?? false}>{x.name}</Name>
              </Table.Cell>
              {anyFilesInlineOpenable && (
                <Table.Cell padding={0.5} shrink={true}>
                  {canFileBeOpenedInline(x.name) && (
                    <DownloadFileIcon
                      contentDisposition="inline"
                      disabled={x.markAsDeleted}
                      source={{ file: x }}
                    />
                  )}
                </Table.Cell>
              )}
              <Table.Cell padding={0.5} shrink={true}>
                <DownloadFileIcon
                  contentDisposition="attachment"
                  disabled={x.markAsDeleted}
                  source={{ file: x }}
                />
              </Table.Cell>
              {props.showRemoveIcon && (
                <Table.Cell padding={0.5} shrink={true}>
                  <Icon
                    icon={['fad', 'trash-can']}
                    title={t('delete', { ns: 'common' })}
                    onClick={() => props.onFileRemoved?.(x)}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

PoiFilesList.propTypes = {
  files: PropTypes.array.isRequired,
  showRemoveIcon: PropTypes.bool.isRequired,
  onFileRemoved: PropTypes.func,
};

const Name = styled.span<{ markAsDeleted: boolean }>`
  ${(props) =>
    props.markAsDeleted &&
    css`
      color: #b22222;
    `}
`;

export { PoiFilesList, Props as PoiFilesListProps };
