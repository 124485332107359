import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'underscore';

import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { viewerZIndices } from '../../../../js/routes/viewer/viewer-z-indices';
import { useDialog } from '../../../hooks/use-dialog';
import { useSharedView } from '../../../hooks/use-shared-view';
import { useSubscribe } from '../../../hooks/use-subscribe';
import { getSelectedProjectId } from '../../../routing/utils';
import { validSkyViewFileExtensions } from '../../../utils/file';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { FileManagerDialog } from '../../file-manager-dialog/file-manager-dialog';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { TextBox } from '../../text-box/text-box';
import { CategoryState } from '../state/category-state';
import { AssetMenuList } from './asset-menu-list';

const AssetMenu = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState('');
  const [showAssetMenuOverlay, setShowAssetMenuOverlay] = React.useState(false);

  const fileManagerDialog = useDialog();
  const { isSharedView } = useSharedView();

  const debounceSearchTextInput = React.useMemo(
    () =>
      _.debounce((value: string) => {
        setSearchText(value);
      }, 200),
    [],
  );

  useSubscribe(SubscriptionTopic.ShowAssetMenuOverlay, () => {
    setShowAssetMenuOverlay(true);
  });

  useSubscribe(SubscriptionTopic.HideAssetMenuOverlay, () => {
    setShowAssetMenuOverlay(false);
  });

  return (
    <>
      <CategoryState>
        <OverlayLoader backgroundColor="#FFFA" height="100%" visible={showAssetMenuOverlay}>
          <Component>
            <SearchContainer>
              <TextBox
                placeholder={t('topArea.searchFieldWatermark', { ns: 'skyviewAssetMenu' })}
                onChange={(e) => debounceSearchTextInput(e.target.value)}
              />
            </SearchContainer>
            <AssetMenuList assetNameFilter={searchText} />
            {!isSharedView && (
              <Footer>
                <Button
                  color="primary"
                  variant="contained"
                  width="100%"
                  onClick={() => fileManagerDialog.show()}
                >
                  {t('bottomArea.openFileManagerButton', { ns: 'skyviewAssetMenu' })}
                </Button>
              </Footer>
            )}
          </Component>
        </OverlayLoader>
      </CategoryState>

      {fileManagerDialog.render(
        <FileManagerDialog
          defaultFileUploadExtensions={validSkyViewFileExtensions}
          onClose={() => {
            fileManagerDialog.hide();

            const projectId = getSelectedProjectId();
            publish(SubscriptionTopic.RefreshAssetList, {
              projectId: projectId!,
              assetTypes: ['Dxf', 'Glb'],
            });
          }}
        />,
      )}
    </>
  );
};

const Footer = styled.div`
  padding: 0 var(--padding) var(--padding) var(--padding);
`;

const Component = styled.div`
  ${reset}

  --padding: 0.8em;

  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: var(--padding);

  height: 100%;

  z-index: ${viewerZIndices.menu};
  background-color: #fff;
  font-size: 14px;
  padding: var(--padding) 0 0 0;
  ${AssetMenuList.styled} {
    padding: 0 var(--padding);
  }

  // Add bottom space to asset menu list if no footer exists.
  &:not(&:has(${Footer})) {
    ${AssetMenuList.styled} {
      padding-bottom: var(--padding);
    }
  }
`;

const SearchContainer = styled.div`
  margin: 0 var(--padding);
`;

export { AssetMenu, Component as AssetMenuStyled };
