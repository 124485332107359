import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { viewerZIndices } from '../../../../js/routes/viewer/viewer-z-indices';
import { SkyView } from '../../../../js/viewer/skyview';
import { SkyviewState } from '../skyview-state';
import { RightMenuAutoExpandContainer } from './right-menu-auto-expand-container';

type Props = {
  skyView: SkyView;
};

const RightMenu = (props: Props) => {
  return (
    // Wrap with SkyViewState as this component is also used from AngularJS SkyView.
    <SkyviewState skyview={props.skyView}>
      <Component />
    </SkyviewState>
  );
};

RightMenu.propTypes = {
  skyView: PropTypes.object.isRequired,
};

const Component = styled(RightMenuAutoExpandContainer)`
  z-index: ${viewerZIndices.menu};
`;

export { RightMenu, Component as RightMenuStyled };
