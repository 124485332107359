import React, { useCallback } from 'react';
import styled from 'styled-components';

import { SubscriptionTopic } from '../../../../../../js/messaging/pubsub';
import { isDefined } from '../../../../../../js/utils/variables';
import { Poi } from '../../../../../../js/viewer/elements/poi';
import { useDialog } from '../../../../../hooks/use-dialog';
import { useSharedView } from '../../../../../hooks/use-shared-view';
import { useSubscribe } from '../../../../../hooks/use-subscribe';
import { Icon } from '../../../../icon/icon';
import { Stack } from '../../../../stack/stack';
import { useSubscribeShowDeleteAssetDialog } from '../../../hooks/subscribe-show-delete-asset-dialog';
import { AssetHeader } from '../../components/asset-blocks/asset-header';
import { DeleteAssetDialog } from '../../dialogs/delete-asset-dialog';
import { PoiDialog } from '../../dialogs/poi-dialog/poi-dialog';
import { DeleteIcon } from '../../icons/delete-icon';
import { EditIcon } from '../../icons/edit-icon';

type Props = {
  asset: Poi;
  refreshAsset: () => void;
};

const PoiHeader = (props: Props) => {
  const editDialog = useDialog();
  const deleteDialog = useDialog();
  const { isSharedView } = useSharedView();
  useSubscribeShowDeleteAssetDialog(props.asset, deleteDialog, isSharedView);

  useSubscribe(SubscriptionTopic.ShowEditPoiDialog, (data) => {
    if (data.poi.uuid === props.asset.uuid) {
      editDialog.show();
    }
  });

  const getPoiTitlePrefix = useCallback(() => {
    const thumbnailUrl = props.asset.getThumbnailResourceUrl('64x_');
    return (
      <PoiPrefix alignItems="center" justifyContent="center">
        {isDefined(thumbnailUrl) ? (
          <PoiThumbnail src={thumbnailUrl} />
        ) : (
          <Icon icon={['fad', 'location-dot']} size="lg" />
        )}
      </PoiPrefix>
    );
  }, [props.asset]);

  return (
    <>
      <AssetHeader
        additionalIcons={
          isSharedView
            ? undefined
            : [
                <EditIcon
                  key="editIcon"
                  onClick={() => {
                    editDialog.show();
                  }}
                />,
                <DeleteIcon
                  key="deleteIcon"
                  onClick={(e) => deleteDialog.show({ x: e.clientX, y: e.clientY })}
                />,
              ]
        }
        asset={props.asset}
        name={props.asset.name}
        namePrefix={getPoiTitlePrefix()}
      />

      {editDialog.render(
        <PoiDialog
          context={{
            type: 'EXISTING',
            poi: props.asset,
          }}
          wrapWithLanguageProvider={true}
          onClose={(saved) => {
            editDialog.hide();

            if (saved) {
              props.refreshAsset();
            }
          }}
        />,
      )}

      {deleteDialog.render((pos) => (
        <DeleteAssetDialog asset={props.asset} pos={pos} onClose={deleteDialog.hide} />
      ))}
    </>
  );
};

const PoiPrefix = styled(Stack)`
  width: 3em;
  height: 3em;
`;

const PoiThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export { PoiHeader, Props as PoiHeaderProps };
